examples = [
    { name="Video Area", description="Draws a white rectangle to the screen", path="area_test", program_text="""
//Draws a rectangle to the screen
def pos 64
def size 5

def white 0xFFFFFFFF

lda @white
mov d0 vc

lda @pos
mov d0 r00
lda @size
mov d0 r01

mul r00 r01
mov a0 vx1
mov a0 vy1

mov r00 vx0
mov r00 vy0

int va
    
"""},
    { name="Hello World", description="Basic hello world example", path="hello_world", program_text="""
// Simple hello world example

def hw "Hello World"
def white 0xFFFFFFFF

lda @hw
mov d0 vt

lda @white
mov d0 vc

int vt 
"""},
    { name="IO Inputs", description="IO Inputs test", path="io_inputs",  program_text="""

// Turning IO outputs on/off with switches.

:handle_io

mov io08 io00
mov io09 io01
mov io10 io02
mov io11 io03
mov io12 io04
mov io13 io05
mov io14 io06
mov io15 io07

int iof

end

intsub io08 handle_io
intsub io09 handle_io
intsub io10 handle_io
intsub io11 handle_io
intsub io12 handle_io
intsub io13 handle_io
intsub io14 handle_io
intsub io15 handle_io

:hang_loop
jmp hang_loop
end

jmp hang_loop    
"""},
    { name="Paint", description="Simple paint program", path="line_paint", program_text="""
//Paint but uses lines
//r00 = mx prev
//r01 = my prev

def white 0xFFFFFFFF
def black 0x000000FF

:handle_mouse_move

mov mx vx1
mov my vy1

mov r00 vx0
mov r01 vy0

int vl

mov mx r00
mov my r01

end

:clear_bg
lda @white
mov d0 vc
int vc

lda @black
mov d0 vc
end

intsub mm handle_mouse_move
intsub kd clear_bg

:loop
jmp loop
end

lda @white
mov d0 vc
int vc

lda @black
mov d0 vc

jmp loop

"""},
    { name="Sound", description="Increasing sound frequency", path="sound", program_text="""
//Outputs a sine wave and increases frequency over time.
def x 10
def t 50
def vol 16

:loop
add r00 r02
mov a0 r00
mov r00 st
int sf
hlt r01
jmp loop
end

lda @x
mov d0 r00
mov d0 r02

lda @t
mov d0 r01

lda @vol
mov d0 sv

jmp loop
"""},
{ name="System Module", description="Grabs the name of the frontend from the system module", path="sys_module", program_text="""
// Interacts with the system module

def white 0xFFFFFFFF
def name_string "Name: "

lda @white
mov d0 vc

clr d0

emi r00
mov d0 vt

lda @name_string
sr vt dl
mov d0 vt

int vt
"""},
{name="Paint keyboard", description="Like paint but uses keyboard presses for drawing", path="paint_keyboard_press", program_text="""
//Paint that uses key presses

def bg 0xffffffff
def line 0x000000ff

lda @bg
mov d0 vc
int vc

:loop
jmp loop
end

:draw_line

mov mx vx1
mov my vy1

int vl

clr a0
decr io00
int iof

call loop

end

:onpress

eq a0 r00
cndjmp draw_line

mov mx vx0
mov my vy0

incr a0
incr io00
int iof

end

intsub kd onpress

lda @line
mov d0 vc

incr r00

jmp loop
"""}
]